export class IntroVideo {
  constructor(container, landscapeVideo, portraitVideo) {
    const orientation = window.matchMedia("(orientation: portrait)")
    const video = orientation.matches ? portraitVideo : landscapeVideo;
    const isAlreadyShown = sessionStorage.getItem('isIntroShown') !== null;

    if (isAlreadyShown) {
      container.style.display = 'none';
    }
    else {
      let isPlaying = false;
      container.style.background = '#87695C';
      document.documentElement.classList.add('not-scrollable');
      
      video.addEventListener('play', () => {
        isPlaying = true;
        sessionStorage.setItem('isIntroShown', "true");
      });

      video.addEventListener('ended', () => {
        isPlaying = false;
        container.classList.add('video--closing');
        document.documentElement.classList.remove('not-scrollable');
      });

      // Workaround to detect low power mode in iOS
      // iOS will pause the video in low power mode, and it also 'suspend' the data loading
      // since we are not 'pausing' the video, and we don't give any video control to the user
      // to pause, therefore we can be sure that the iOS is pausing the video
      video.addEventListener('pause', () => {
        isPlaying = false;
      })
      video.addEventListener('suspend', () => {
        if (!isPlaying) {
          container.classList.add('video--closing');
          document.documentElement.classList.remove('not-scrollable');
        }
      });

      // play video
      video.play();
    }
  }
}