export class OffCanvasMenu {
  constructor(menuContainer) {
    // Close popup when ESC is pressed
    window.addEventListener('keyup', (event) => {
      if (event.key === 'Escape')
        this.close();
    });

    menuContainer.querySelectorAll('ul a')
      .forEach(el => el.addEventListener('click', (event) => {
        // Wait until menu closed before scrolling to the anchor
        const href = event.target.attributes.href.value
        const isAnchor = href.startsWith('#');
        if (isAnchor) {
          event.preventDefault();
          menuContainer.addEventListener(
            'transitionend',
            () => window.location = href,
            { once: true }
          )
        }

        this.close();
      }));

    document.querySelector('.offcanvas-menu__body')
      .addEventListener('click', () => this.close());
  }

  open() {
    document.documentElement.classList.add('menu--opened');
  }

  close() {
    document.documentElement.classList.remove('menu--opened');
  }

  openWhenClicked(selector) {
    document.querySelectorAll(selector)
      .forEach(
        el => el.addEventListener('click', this._openBtnClicked.bind(this))
      );
  }

  _openBtnClicked(event) {
    event.preventDefault();
    this.open();
  }
}