export class ScrollObserver {
  _defaultOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
    once: true,
    exit: false,
    className: 'in-viewport',
    exitClassName: 'out-viewport'
  }

  _optionsMap = new Map();

  constructor(options) {
    this.options = {...this._defaultOptions, ...options};
  }

  observe(selector, options) {
    options = {...this.options, ...options};

    // Fallback if the browser doesn't support Intersection Observer
    if(!('IntersectionObserver' in window)) {
      document.querySelectorAll(selector)
        .forEach(target =>
          target.classList.add(options.className)
        );
      return
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    options.observer = new IntersectionObserver(
      this._observerCallback.bind(this),
      options
    );

    document.querySelectorAll(selector)
      .forEach(target => {
        this._optionsMap.set(target, options);
        options.observer.observe(target);
      });

    return this;
  }

  _observerCallback(entries) {
    entries.forEach(entry => {
      const options = this._optionsMap.get(entry.target);

      if (entry.isIntersecting) {
        entry.target.classList.add(options.className);
        entry.target.classList.remove(options.exitClassName);

        if (options.once) {
          options.observer.unobserve(entry.target);
          this._optionsMap.delete(entry.target);
        }
      } else if (options.exit) {
        entry.target.classList.remove(options.className);
        entry.target.classList.add(options.exitClassName);
      }
    });
  }
}