let _player, _cover, _button;

export function Video() {
  const video = document.getElementById('player');
  if (!video) return;

  _cover = document.querySelector('.video-cover');
  _button = document.querySelector('.video-button');
  _button.addEventListener('click', _playVideo);

  _player = new YT.Player(video, {
    videoId: video.dataset.videoId,
    playerVars: {
      rel: 0,
      modestbranding: 1,
      showinfo: 0,
      controls: 0,
      enablejsapi: 1,
    },
    events: {
      'onStateChange': _onPlayerStateChange
    }
  })
}

function _onPlayerStateChange(event) {
  switch (event.data) {
    case YT.PlayerState.PAUSED:
    case YT.PlayerState.ENDED:
      _hideVideo();
      break;
    case YT.PlayerState.PLAYING:
      _showVideo();
      break;
    default:
      break;
  }
}

function _playVideo() {
  if (!_player) return;

  _showVideo();
  _player.playVideo();
}

function _showVideo() {
  _cover.style.display = 'none';
  _button.style.display = 'none';
}

function _hideVideo() {
  _cover.style.display = 'block';
  _button.style.display = 'block';
}
