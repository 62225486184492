import Glide from '@glidejs/glide/dist/glide.min';
import {ScrollObserver} from "./components/scroller";
import {Video} from "./components/video";
import {RegistrationForm} from "./components/form";
import {Popup} from "./components/popup";
import {OffCanvasMenu} from "./components/off-canvas-menu";
import {IntroVideo} from "./components/intro";

window.onYouTubeIframeAPIReady = () => Video();

window.addEventListener('DOMContentLoaded', () => {
  // Intro video
  new IntroVideo(
    document.getElementById('intro-section'),
    document.getElementById('intro-video-landscape'),
    document.getElementById('intro-video-portrait')
  )

  // Offscreen menu
  const offscreenMenu = new OffCanvasMenu(document.getElementById('offcanvas-menu'));
  offscreenMenu.openWhenClicked('[href="#menu"]');

  // Handle popup
  const popupFormContainer = document.getElementById('form-popup-container');
  const popupForm = new Popup(popupFormContainer)
  popupForm.openWhenClicked('[href="#registration-form"]');
  popupForm.on('opened', () => {
    const firstInput = popupFormContainer.querySelector('input:not([type="hidden"])');
    if (firstInput) firstInput.focus();
  });

  const termsPopupContainer = document.getElementById('terms-and-conditions-popup');
  const popupTerms = new Popup(termsPopupContainer);
  popupTerms.openWhenClicked('[href="#disclaimer"]');

  // Handle form submission
  new RegistrationForm(document.getElementById('registration'));
  new RegistrationForm(popupFormContainer);

  const interiorCarousel = document.getElementById('section-interior-carousel');
  if (interiorCarousel) {
      new Glide(interiorCarousel, {
      bound: true,
      rewind: false
    }).mount();
  }

  const poiCarousel = document.getElementById('section-poi-carousel');
  if (poiCarousel) {
    new Glide(poiCarousel, {
      type: 'carousel',
      startAt: 1,
      gap: 4,
      bound: true,
      peek: 50,
      perView: 5,
      breakpoints: {
        500: { perView: 1 },
        1280: { perView: 3 },
        1920: { perView: 4 }
      }
    }).mount();
  }

  const observer = new ScrollObserver();
  observer
    .observe('#section-balance', {threshold: 0.75})
    .observe('#interior-design .col-2')
    .observe('#section-hero', {once: false, threshold: 0, exit: true})
})
