export class RegistrationForm {
  constructor(formContainer) {
    if (!formContainer) return;

    this.form = formContainer.getElementsByTagName('form')[0];
    this.form.addEventListener('submit', this._submitForm.bind(this));

    this.messageEl = formContainer.querySelector('.form-result');
  }

  _submitForm(event) {
    event.preventDefault();

    const options = {
      method: 'POST',
      body: this._serializeForm(this.form),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    fetch(this.form.action, options)
      .then(response => {
        if (!response.ok) throw "Not OK";

        // window.location.replace('//' + location.host + '/thank-you/');
        this.form.style.display = 'none';

        this.messageEl.classList.remove('error');
        this.messageEl.innerHTML = 'Thank you for registering your interest, we will contact you soon.';
        this.messageEl.style.display = 'block';
        this.form.reset();
      })
      .catch(error => {
        this.messageEl.innerHTML = 'There was an error trying to send your message. Please try again later.';
        this.messageEl.classList.add('error');
        this.messageEl.style.display = 'block';
      })
  }

  _serializeForm(form) {
    const kvPairs = [];
    for (var i = 0; i < form.elements.length; i++) {
      const el = form.elements[i];
      kvPairs.push(encodeURIComponent(el.name) + "=" + encodeURIComponent(el.value));
    }
    return kvPairs.join("&");
  }
}