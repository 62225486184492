export class Popup {
  constructor(container) {
    this.eventListener = new Map([ [ 'opened', [] ], [ 'closed', [] ] ]);
    this.container = container;
    this.closeBtns = container.querySelectorAll('.popup-close');
    this.closeBtns.forEach(btn =>
      btn.addEventListener('click', this._closeBtnClicked.bind(this))
    );

    // Close popup when ESC is pressed
    window.addEventListener('keyup', (event) => {
      if (event.key === 'Escape')
        this.close();
    })
  }

  _closeBtnClicked(event) {
    event.preventDefault();
    this.close();
  }

  _openBtnClicked(event) {
    event.preventDefault();
    this.open();
  }

  isOpened() {
    return this.container.classList.contains('popup--opened');
  }

  open() {
    if (this.isOpened()) return;

    document.documentElement.classList.add('not-scrollable');
    this.container.classList.add('popup--opened');
    this.eventListener.get('opened').forEach(cb => cb());
  }

  close() {
    if (! this.isOpened()) return;

    document.documentElement.classList.remove('not-scrollable');
    this.container.classList.remove('popup--opened');
    this.eventListener.get('closed').forEach(cb => cb())
  }

  openWhenClicked(selector) {
    document.querySelectorAll(selector)
      .forEach(
        el => el.addEventListener('click', this._openBtnClicked.bind(this))
      );
  }

  on(event, callback) {
    if (!['opened', 'closed'].includes(event)) {
      throw "Not a valid popup event";
    }
    const listeners = this.eventListener.has(event) ? this.eventListener.get(event) : [];
    listeners.push(callback);
    this.eventListener.set(event, listeners);
  }
}